var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(" Registration - step 1 ")
      ]),
      _c("gov-back-link", { attrs: { to: { name: "dashboard" } } }, [
        _vm._v("\n    Back to dashboard\n  ")
      ]),
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(" Is your Organisation already listed? ")
      ]),
      _c("gov-body", [
        _vm._v(
          "\n    Before you register an account on " +
            _vm._s(_vm.appName) +
            ", we need to check if your\n    organisation is already registered in our database.\n  "
        )
      ]),
      _c("gov-body", [
        _vm._v(
          "Type the name of your organisation below to check if it is already\n    registered."
        )
      ]),
      _c("organisation-search", { on: { selected: _vm.onSelected } }),
      _c("gov-body", [_vm._v('Select your organisation and click "next"')]),
      _c(
        "gov-button",
        {
          attrs: {
            start: "",
            to: { name: "register-existing-step2" },
            disabled: !_vm.organisationSelected
          },
          on: {
            click: function($event) {
              return _vm.$emit("update", _vm.registration)
            }
          }
        },
        [_vm._v("\n    Next\n  ")]
      ),
      _c(
        "gov-body",
        [
          _vm._v("Can’t find your organisation?\n    "),
          _c("gov-link", { on: { click: _vm.resetOrganisation } }, [
            _vm._v("Register new organisation")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }