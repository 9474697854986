var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "full" } },
            [
              _c(
                "gov-form-group",
                [
                  _c("gov-label", { attrs: { for: "filter[name]" } }, [
                    _vm._v("Organisation name")
                  ]),
                  _c("gov-input", {
                    attrs: {
                      id: "filter[name]",
                      name: "filter[name]",
                      type: "search"
                    },
                    on: { input: _vm.onSearch },
                    model: {
                      value: _vm.filters.name,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "name", $$v)
                      },
                      expression: "filters.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "full" } },
            [
              _c(
                "gov-radios",
                _vm._l(_vm.organisations, function(org) {
                  return _c("gov-radio", {
                    key: org.id,
                    attrs: {
                      "bind-to": _vm.organisationId,
                      name: "organisation",
                      label: org.name,
                      id: org.id,
                      value: org.id
                    },
                    on: { input: _vm.onSelect }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }